import { createAppConfig } from '@newcross-tech/app-config-core';
import * as y from 'yup';

export const AppConfig = createAppConfig({
  parse: (values) =>
    y
      .object({
        apiBaseUrl: y.string().required(),
        newBookingOnly: y.boolean().required(),
        allowDebug: y.boolean().required(),
        sentry: y.object({
          enabled: y.boolean().required(),
        }),
        pendo: y.object({
          enabled: y.boolean().required(),
        }),
        maze: y.object({
          enabled: y.boolean().required(),
        }),
      })
      .required()
      .validateSync(values, { strict: true, recursive: true }),
});
